<template>
  <v-row class="public-new-search search-address">
    <v-col
      cols="12"
      sm="8"
    >
      <div class="main-panel">
        <div class="search-area">
          <p class="text-h4 font-weight-bold text-center">
            Hitta priset på bredband till ditt företag
          </p>
          <v-autocomplete
            v-model="organization"
            :items="companyList"
            :loading="isLoading"
            :search-input.sync="seachCompanyName"
            class="mb-4"
            item-text="companyName"
            item-value="orgNr"
            :append-icon="null"
            return-object
            hide-details
            hide-no-data
            clearable
            :filter="() => true"
            @blur="() => resetCompanyList()"
          >
            <template v-slot:label>
              <span>
                Företagsnamn
              </span>
            </template>
            <template v-slot:selection="data">
              <span>
                {{ data.item.companyName }}
              </span>
            </template>
            <template v-slot:item="data">
              {{ data.item.companyName }}
            </template>
          </v-autocomplete>

          <template v-if="organization">
            <v-radio-group
              v-model="addressType"
              class="mt-8"
              row
            >
              <v-radio value="default">
                <template #label>
                  <span class="text-body-2 mb-0">
                    Addressen nedan
                  </span>
                </template>
              </v-radio>
              <v-radio value="custom">
                <template #label>
                  <span class="text-body-2 mb-0">
                    Använd annan address
                  </span>
                </template>
              </v-radio>
            </v-radio-group>

            <template v-if="addressType === 'default'">
              <p class="text-body-1 purple--text mt-5">{{ companyAddress }}</p>
              <v-btn
                class="btn-next"
                style="height: 20px !important;"
                @click="fetchAccessesByAddress"
              >
                Sök tillgängliga tjänster
              </v-btn>
            </template>

            <v-autocomplete
              v-else
              v-model="streetName"
              :items="streetCityList"
              :loading="isLoading"
              :search-input.sync="searchStreet"
              class="mb-4"
              item-text="value"
              item-value="value"
              :append-icon="null"
              prepend-icon="mdi-map-marker"
              return-object
              hide-details
              hide-no-data
              clearable
              :filter="() => true"
              @blur="() => resetAutoSuggestionList()"
            >
              <template v-slot:label>
                <span>
                  Gata och nummer
                </span>
              </template>
              <template v-slot:selection="data">
                <span>
                  {{ data.item.value }}
                </span>
              </template>
              <template v-slot:item="data">
                <v-icon class="pr-3">
                  mdi-map-marker
                </v-icon> {{ data.item.value }}
              </template>
            </v-autocomplete>
          </template>
        </div>
      </div>
    </v-col>

    <v-col
      cols="12"
      sm="4"
    >
      <img src="/static/img/search/search_side.png" style="height: 100%;">
    </v-col>
  </v-row>
</template>

<script>
  import * as _ from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    data () {
      return {
        isLoading: false,
        organization: null,
        companyList: [],
        signatoryList: [],
        seachCompanyName: null,
        address: null,
        accesses: null,
        addressType: 'default',
        streetName: '',
        streetCityList: [],
        searchStreet: null,
      }
    },

    computed: {
      companyAddress() {
        if (!this.address) {
          return ''
        }

        return `${this.address.street}, ${util.formatPostalCode(this.address.postalCode)} ${this.address.locality}`
      },
    },

    watch: {
      seachCompanyName: {
        handler: _.debounce(function (value) {
          if (this.isLoading || !value || value.length === 0) {
            this.companyList = []
            return
          }

          this.getCompanyList(value)
        }, 500),
      },

      organization (value) {
        if (!value) {
          this.companyList = []
          this.signatoryList = []
          this.address = null
          return
        }

        this.chooseCompany(value)
      },

      searchStreet: {
        handler: _.debounce(function (value) {
          if (this.isLoading || !value || value.length === 0) {
            this.streetCityList = []
            return
          }

          this.getSuggestionListWithStreetAndCity(value)
        }, 500),
      },

      streetName (value) {
        if (!value) {
          this.streetCityList = []
          this.address = null
          return
        }

        this.chooseStreetAndCity(value)
      },

      addressType (value) {
        if (value === 'default') {
          this.address = {
            street: this.organization?.street || '',
            locality: this.organization?.city || '',
            postalCode: this.organization?.zipCode || '',
          }
        } else {
          this.address = null
        }
      },
    },

    methods: {
      ...mapActions({
        searchCompanies: 'searchCompanies',
        getSingatoryOrganization: 'getSingatoryOrganization',
        getSuggestionAddress: 'getSuggestionAddress',
        getAccessAndProducts: 'getAccessAndProducts',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        getValidAddress: 'getValidAddress',
      }),

      async getCompanyList(searchKey) {
        const payload = {
          searchKey,
        }

        this.address = null
        this.isLoading = true
        try {
          this.companyList = await this.searchCompanies(payload)
        } catch (error) {
          this.companyList = []
        }
        this.isLoading = false
      },

      async chooseCompany(result) {
        const queryParams = {
          street: result.street,
          locality: result.city,
          postalCode: result.zipCode,
        }

        this.setLoading(true)

        try {
          const response = await this.getValidAddress(queryParams)

          this.address = {
            street: response.street,
            streetNumber: response.streetNumber,
            streetLetter: response.streetLetter,
            locality: response.locality,
            postalCode: response.postalCode,
          }
        } catch (error) {
          this.address = {
            street: result.street,
            locality: result.city,
            postalCode: result.zipCode,
          }

          this.addressType = 'custom'
        }

        this.signatoryList = []
        await this.getSignatoryList()
      },

      async getSignatoryList() {
        this.setLoading(true)
        this.setLoadingText('Loading Signatory List...')

        try {
          const response = await this.getSingatoryOrganization({
            organizationNumber: this.organization.orgNr,
          })
          this.signatoryList = response.signatoryList.map((item) => item[0])
        } catch (error) {
          this.signatoryList = []
        }
        this.setLoading(false)
      },

      async getSuggestionListWithStreetAndCity(queryString) {
        const payload = {
          query: queryString,
          columns: 'street,locality, postalcode,street_number,letter',
        }

        this.address = null
        this.isLoading = true
        try {
          const response = await this.getSuggestionAddress(payload)
          this.streetCityList = response.rows.map(item => ({
            value: util.getFullAddress(item),
            streetLetter: item.letter,
            street: item.street,
            streetNumber: item.street_number,
            locality: item.locality,
            postalCode: item.postalcode,
          }))
        } catch (error) {
          this.streetCityList = []
        }
        this.isLoading = false
      },

      chooseStreetAndCity(result) {
        this.address = result
        this.fetchAccessesByAddress()
      },

      async fetchAccessesByAddress() {
        this.setLoading(true)
        this.setLoadingText('Laddar Tiillgängliga Tjänster...')

        this.accesses = null

        try {
          const payload = {
            street: this.address.street,
            streetNumber: this.address.streetNumber,
            streetLetter: this.address.streetLetter,
            locality: this.address.locality,
            postalCode: this.address.postalCode,
          }

          this.accesses = await this.getAccessAndProducts(payload)

          this.$emit('set-company', this.organization)
          this.$emit('set-signatory-list', this.signatoryList)
          this.$emit('set-address', this.address)
          this.$emit('set-accesses', this.accesses)
          this.$emit('go-to-next')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
          this.setLoading(false)
        }
      },

      resetCompanyList() {
        if (this.organization) {
          return
        }

        this.seachCompanyName = ''
        this.companyList = []
      },

      resetAutoSuggestionList() {
        if (this.streetName) {
          return
        }

        this.searchStreet = ''
        this.streetCityList = []
      },
    },
  }
</script>

<style lang="scss">
.public-new-search.search-address {
  .search-area {
    .v-input.v-autocomplete,
    .v-input.v-select {
      flex: 0;
      width: 70%;
      min-width: 350px;
      margin-top: 30px;
    }
  }
}


</style>