<template>
  <v-form
    ref="form"
    v-model="validForm"
    lazy-validation
    class="public-new-search order-info"
  >
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="9" class="info-area">
            <p class="text-h4 font-weight-bold">
              Kassa
            </p>
            <p class="text-subtitle-1 grey--text text--lighten-2">
              Du är nästan klar! Steg 1/2 Fyll i dina uppgifter och steg 2/2 slutför beställning!
            </p>
          </v-col>
          <v-col cols="3" class="image-area" />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="8">
        <p class="text-h4 font-weight-bold mb-2">
          <span class="text-purple">1/2</span> Fyll i dina uppgifter
        </p>

        <v-row>
          <v-col cols="6">
            <span class="text-subtitle-1 font-weight-bold">Personnummer</span>
            <v-select
              v-if="signatoryList.length > 0"
              v-model="socialSecurityNumber"
              :items="signatoryList"
              label="Signatory"
              hide-details
              item-text="name"
              item-value="ssn"
            />
            <v-text-field
              v-else
              v-model="socialSecurityNumber"
              :rules="securityNumberRules"
              placeholder="ÅÅÅÅMMDD XXXX"
              outlined
              hide-details
              @input="validateSocialSecurityNumber"
            />
            <span class="grey--text">
              För att bekräfta din identitet
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <span class="text-subtitle-1 font-weight-bold">E-postadress</span>
            <v-text-field
              v-model="email"
              type="email"
              :rules="requiredEmailRules"
              placeholder="exampel@exampel.se"
              hide-details
              outlined
              @blur="onChangeEmail"
              @change="onChangeEmail"
            />
            <span class="grey--text">
              För orderbekräftelse
            </span>
          </v-col>

          <v-col cols="6">
            <span class="text-subtitle-1 font-weight-bold">Mobilnummer</span>
            <v-text-field
              v-model="phoneNumber"
              :rules="phoneNumberRules"
              placeholder="07X XXX XX XX"
              hide-details
              outlined
            />
            <span class="grey--text">
              För avisering via sms
            </span>
          </v-col>
        </v-row>

        <p class="text-h4 font-weight-bold mb-2 mt-6">
          Adress for bredband
        </p>

        <div class="delivery-address text-subtitle-1">
          {{ addressText }}<br>
          {{ util.formatPostalCode(address.postalCode) }} {{ address.locality }}
        </div>

        <p class="text-h4 font-weight-bold mb-2 mt-10">
          Leveransinformation
        </p>

        <div class="delivery-information-area">
          <div class="info-item text-subtitle-1">
            Aktivering sker snarast, ofta kan du använda bredbandet samma dag
          </div>

          <div class="info-item text-subtitle-1">
            <p class="pb-0 mb-0"><strong>WIFI router ingår inte</strong> i vårt abonnemang - vi kan rekommendera om du behöver</p>
            <p class="text-purple pb-0 mb-0">
              <a href="https://google.com" target="_blank" style="text-decoration: underline;" @click.stop>Kontakta kundservice</a>
            </p>
          </div>

          <div class="info-item text-subtitle-1">
            Inställningar på din utrustning ska stå på automatisk (DHCP)
          </div>
        </div>

        <p class="text-h4 font-weight-bold mb-2 mt-10">
          <span class="text-purple">2/2</span> Betalsätt
        </p>

        <div class="payment-information-area">
          <div class="info-item text-subtitle-1">
            <p class="pb-0 mb-0 font-weight-bold">
              Din första faktura kommer skickas med e-post
            </p>
            <p class="pt-1 pb-0 mb-0 grey--text" style="line-height: 1.2;">
              <span>
                Önskar du E-faktura så anmäler du dig i din bank - Sök Servercentralen - Bra Bredband. Påminnelser skickas via Kivra primärt eller via post.
              </span>
            </p>
          </div>

          <v-checkbox
            v-model="isAgreeGeneralTerm"
            class="mt-4"
            hide-details
          >
            <template v-slot:label>
              <span class="grey--text">
                Jag godkänner gällande
                <a href="https://google.com" target="_blank" @click.stop>allmänna villkor</a>
                och att mina personuppgifter behandlas i enlighet med gällande
                <a href="https://google.com" target="_blank" @click.stop>integritetspolicy</a>,
                samt att en kreditupplysning genomförs när jag beställer ett abonnemang.
              </span>
            </template>
          </v-checkbox>

          <v-checkbox
            v-model="isAgreePersonalTerm"
            class="mt-4"
            hide-details
          >
            <template v-slot:label>
              <span class="grey--text">
                Jag samtycker till att
                <a href="https://google.com" target="_blank" @click.stop>Brabredband behandlar personuppgifter</a>
                om mig som är s.k. trafikuppgifter, (t.ex. om hur jag använder tjänsten) för attför att förbättra tjänster och nät.
                Hur länge uppgifterna sparas, se
                <a href="https://google.com" target="_blank" @click.stop>Brabredbands integritetspolicy</a>.
                Jag kan, när som helst, ta tillbaka samtycket via
                <a href="https://google.com" target="_blank" @click.stop>Kundservice</a>.
              </span>
            </template>
          </v-checkbox>
        </div>

        <div class="actions-area d-flex justify-space-between mt-6">
          <v-btn
            class="btn-back"
            @click="gotoBack"
          >
            Tillbaka
          </v-btn>

          <v-btn
            class="btn-next"
            :disabled="!validForm || !isAgreeGeneralTerm || !isAgreePersonalTerm"
            @click="signWithPersonalNumber"
          >
            Slutför köp
          </v-btn>
        </div>
      </v-col>

      <v-col cols="3" offset="1">
        <p class="text-h4 font-weight-bold mb-2">
          Din beställning
        </p>

        <div class="product-info">
          <div class="product-info-item">
            <span>Bredband</span>
            <span class="text-subtitle-1 font-weight-bold">{{ product.speed }} Mbit/s</span>
          </div>

          <div class="product-info-item">
            <span>Stadsnät</span>
            <span class="text-subtitle-1 font-weight-bold">{{ product.network || 'Stokab' }}</span>
          </div>

          <div class="product-info-item">
            <span>Startdatum</span>
            <span class="text-subtitle-1 font-weight-bold">{{ expectedDelivery }}</span>
          </div>

          <hr class="mt-2">

          <div class="product-info-item">
            <span>Startkostand</span>
            <span class="text-subtitle-1 font-weight-bold">{{ product.start }} SEK</span>
          </div>

          <div class="product-info-item">
            <span>Månadskostnad</span>
            <span class="text-subtitle-1 font-weight-bold">{{ product.price }} SEK</span>
          </div>

          <hr class="mt-2">

          <div class="product-info-item">
            <span>Total</span>
            <span class="text-subtitle-1 font-weight-bold">{{ product.price + product.start }} SEK</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapActions } from 'vuex'
  import Personnummer from 'personnummer'
  import Swal from 'sweetalert2'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  export default {
    props: {
      product: {
        type: Object,
        default: () => ({}),
      },

      service: {
        type: Object,
        default: () => ({}),
      },

      access: {
        type: Object,
        default: () => ({}),
      },

      address: {
        type: Object,
        default: () => ({}),
      },

      expectedDelivery: {
        type: String,
      },

      stokabProduct: {
        type: Object,
        default: () => ({}),
      },

      signatoryList: {
        type: Array,
        default: () => ([]),
      },

      organization: {
        type: Object,
        default: () => ({}),
      },
    },

    data () {
      return {
        validForm: true,
        socialSecurityNumber: '',
        isValidSocialSecurityNumber: false,
        email: '',
        phoneNumber: '',
        isAgreeGeneralTerm: false,
        isAgreePersonalTerm: false,

        securityNumberRules: [
          (v) => !!v || 'Obligatorisk',
          (v) => !v || this.isValidSocialSecurityNumber || 'Ange giltigt personnummer',
        ],
        requiredEmailRules: [
          (v) => !!v || 'Obligatorisk',
          (v) => !v || !(/^mailto:/i.test(v)) || this.$t('error.excludeMailtoPrefix'),
          (v) => !v || /.+@.+\..+/.test(v) || 'E-post måste vara giltig',
        ],
        phoneNumberRules: [
          (v) => !!v || 'Obligatorisk',
          (v) => !v || v.startsWith('+') || /^((((0{2}?)|(\+?){1})46)|0)7[\d]{8}/.test(v) || 'Felaktigt telefonnummer ex: +46712345678, 46712345678, 0712345678',
        ],

        util: util,
      }
    },

    computed: {
      addressText() {
        if (!this.address) {
          return ''
        }

        let fullAddress = `${this.address.street} ${this.address.streetNumber}`

        if (this.address.streetLetter) {
          fullAddress = `${fullAddress} ${this.address.streetLetter}`
        }

        if (!this.access || (!this.access.mduApartmentNumber && !this.access.mduDistinguisher)) {
          return fullAddress
        }

        if (this.access.mduApartmentNumber && this.access.mduDistinguisher) {
          fullAddress = `${fullAddress} (${this.access.mduApartmentNumber}/${this.access.mduDistinguisher})`
        } else {
          fullAddress = `${fullAddress} (${this.access.mduApartmentNumber || this.access.mduDistinguisher})`
        }

        return fullAddress
      },
    },

    mounted() {
      const grandidsession = this.$route.query.grandidsession || ''

      if (!grandidsession) {
        return
      }

      this.prefillOrderData(grandidsession)
    },

    methods: {
      ...mapActions({
        createOrder: 'createOrder',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        signWithBankId: 'signWithBankId',
      }),

      prefillOrderData(sessionId) {
        const orderData = JSON.parse(localStorage.getItem('orderData'))
        if (!orderData) {
          return
        }

        this.socialSecurityNumber = orderData.socialSecurityNumber
        this.email = orderData.contactInfo && orderData.contactInfo.contactList && orderData.contactInfo.contactList[0].email
        this.phoneNumber = orderData.contactInfo && orderData.contactInfo.contactList && orderData.contactInfo.contactList[0].phoneNumber
        this.isAgreeGeneralTerm = true
        this.isAgreePersonalTerm = true

        this.order(sessionId)
      },

      validateSocialSecurityNumber() {
        if (!this.socialSecurityNumber) {
          this.isValidSocialSecurityNumber = false
          return
        }

        this.isValidSocialSecurityNumber = Personnummer.valid(
          this.socialSecurityNumber
        )
      },

      gotoBack() {
        this.$emit('go-to-back')
      },

      onChangeEmail(e) {
        if (this.email && this.email.startsWith('mailto:')) {
          this.email = this.email.replace(/^mailto:/i, '')
        }
      },

      async signWithPersonalNumber () {
        const isValidForm = this.$refs.form.validate()

        if (!isValidForm) {
          return
        }

        const parsedSocialSecurityNumber = Personnummer.parse(this.socialSecurityNumber);

        if (parsedSocialSecurityNumber) {
          this.socialSecurityNumber = parsedSocialSecurityNumber.format(true);
        }

        const formData = {
          personalNumber: this.socialSecurityNumber,
          networkInfo: this.product,
          address: {
            street: this.address.street,
            locality: this.address.locality,
            postalCode: this.address.postalCode,
            streetNumber: this.address.streetNumber,
            streetLetter: this.address.streetLetter,
          },
          reference: '',
          endCustomer: '',
          roomDescription: '',
          customerSiteReference: '',
          expectedDelivery: this.expectedDelivery,
          wantToOrderHouse: false,
          contactInfo: {
            isSendDeliveryToLocalContactPersons: false,
            contactList: [{
              firstName: '',
              lastName: '',
              type: 'primary',
              email: this.email,
              confirmEmail: this.email,
              phoneNumber: this.phoneNumber,
              cellPhoneNumber: this.phoneNumber,
            }],
          },
          addOnData: [],
          startCost: this.product.start,
          monthlyCost: this.product.price,
          stokabProduct: this.stokabProduct,
          orderType: 'automatic',
          offerInquiry: null,
          service: this.service,
          access: this.stokabProduct ? null : this.access,
          socialSecurityNumber: this.socialSecurityNumber,
          business: 'commercial',
          organizationNumber: this.organization.orgNr,
        }

        let orderInfoText = `Du accepterar ett avtal om beställning för en bredbandsanslutning till ${this.address.street} ${this.address.streetNumber}, ${this.address.city} med begärt leveransdag ${formData.expectedDelivery}.\n\n`
        orderInfoText += `Kapacitet: ${this.product.downSpeed}/${this.product.upSpeed} Mbit/s\n\n`
        orderInfoText += `Total kostnad ${this.product.price} per månad och ${this.product.start} i startkostnad.\n\n`
        orderInfoText += `Initial bindningstid: ${this.product.term} månader\n\n`
        orderInfoText += 'Avbeställning enligt våra generella och särskilda villkor på hemsidan'

        this.setLoadingText(`Signera med bankid ${this.socialSecurityNumber}`)
        this.setLoading(true)

        let redirectUrl = ''

        try {
          const payload = {
            personalNumber: this.socialSecurityNumber,
            orderInfo: orderInfoText,
            redirectionUrl: window.location.href,
          }

          const response = await this.signWithBankId(payload)

          redirectUrl = response.redirectUrl
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
        }

        if (redirectUrl) {
          localStorage.setItem('orderData', JSON.stringify(formData))
          localStorage.setItem('product', JSON.stringify(this.product))
          localStorage.setItem('service', JSON.stringify(this.service))
          localStorage.setItem('access', JSON.stringify(this.access))
          localStorage.setItem('address', JSON.stringify(this.address))
          localStorage.setItem('stokabProduct', JSON.stringify(this.stokabProduct))
          localStorage.setItem('expectedDelivery', this.expectedDelivery)
          location.href = redirectUrl
        }

        this.setLoading(false)
      },

      async order(sessionId) {
        const formData = JSON.parse(localStorage.getItem('orderData'))

        if (!formData) {
          return
        }

        this.setLoadingText('Skapar ordern...')
        this.setLoading(true)
        try {
          await this.createOrder({
            ...formData,
            sessionId,
          })
          localStorage.removeItem('orderData')

          this.setLoading(false)
          this.$emit('set-order-data', formData)

          this.$emit('go-to-next')
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
          this.setLoading(false)

          localStorage.removeItem('orderData')
        }
      },
    }
  }
</script>

<style lang="scss">
.order-info {
  .info-area {
    background: #5B46F6;
    border-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: #f4f3f9;
    padding: 20px 40px;
  }

  .image-area {
    border-radius: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-size: cover;
    background-image: url('/static/img/search/order_info.png');
  }

  .delivery-address {
    border: 1px solid #e1e0e9;
    border-radius: 15px;
    padding: 20px 30px;
  }

  .info-item {
    border: 1px solid #e1e0e9;
    padding: 20px 20px;
    margin-top: 20px;
    border-radius: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  .product-info {
    background: #e4e3ef;
    border-radius: 15px;
    padding: 20px 30px;

    .product-info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      span:first-child {
        color: #5B46F6;
        font-size: 14px;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .payment-information-area {
    .v-input .v-input__slot {
      align-items: flex-start;
    }
  }

  .text-purple {
    color: #5B46F6;
  }

  .actions-area {
    .btn-back {
      flex: 0.4;
    }
    .btn-next {
      flex: 0.6;
      margin-left: 20px;
    }
  }
}
</style>
