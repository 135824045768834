<template>
  <v-row class="public-new-search">
    <v-col cols="12" sm="8" offset-sm="2">
      <v-row>
        <v-col cols="12" sm="8">
          <div class="order-confirmation">
            <p class="text-h4 font-weight-bold text-center">
              Tack för din beställning!
            </p>

            <p class="text-subtitle-1 font-weight-bold mb-1 mt-4">
              Du har valt:
            </p>

            <div class="product-info">
              <div class="product-info-item">
                <span>Bredband</span>
                <span class="text-subtitle-1 font-weight-bold">{{ orderData.networkInfo.speed }} Mbit/s</span>
              </div>

              <div class="product-info-item">
                <span>Stadsnät</span>
                <span class="text-subtitle-1 font-weight-bold">{{ orderData.access.networkName }}</span>
              </div>

              <div class="product-info-item">
                <span>Startdatum</span>
                <span class="text-subtitle-1 font-weight-bold">{{ orderData.expectedDelivery }}</span>
              </div>
            </div>

            <p class="text-subtitle-1 font-weight-bold mt-6 mb-1">
              Din första faktura kommer via e-post:
            </p>

            <div class="product-info">
              <span class="text-subtitle-1">{{ orderData.contactInfo.contactList[0].email }}</span>
            </div>
            <span class="grey--text mt-1">Angiven epost</span>

            <div class="actions-area d-flex flex-column mt-6">
              <v-btn
                class=" btn-next"
                @click="gotoStart"
              >
                Till startsidan
              </v-btn>

              <v-btn
                class="btn-back mt-1"
                @click="contactSupport"
              >
                Kontakta kundservice
              </v-btn>
            </div>
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <img src="/static/img/search/order_confirmation.png">
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    props: {
      orderData: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      gotoStart () {
        this.$emit('init-data')
      },

      contactSupport () {
        console.log('Contact Support')
      },
    },
  }
</script>

<style lang="scss">
  .order-confirmation {
    height: 100%;
    border-radius: 15px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    border: 2px solid #e4e3ef;

    .product-info {
      background: #e4e3ef;
      border-radius: 15px;
      padding: 20px 30px;

      .product-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        span:first-child {
          color: #5B46F6;
          font-size: 14px;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
</style>