<template>
  <v-row class="public-new-search choose-network">
    <v-col cols="12">
      <v-row>
        <v-col cols="8" class="info-area">
          <p class="text-h4 font-weight-bold">
            Absolut!, vi har leveransmöjligheter till din address!
          </p>
          <div class="d-flex">
            <div class="info-item">
              <label class="text-caption">Adress du sökte</label>
              <p class="info-item-value text-subtitle-1 d-flex align-center">
                <v-icon
                  small
                  dense
                  color="white"
                  class="pr-1"
                >
                  mdi-map-marker
                </v-icon>
                {{ address }}
              </p>
            </div>

            <div class="info-item">
              <label class="text-caption">Tillgänglig operätör</label>
              <p class="info-item-value text-subtitle-1 d-flex align-center">
                <v-icon
                  small
                  dense
                  color="white"
                  class="pr-2"
                >
                  mdi-wifi
                </v-icon>
                {{ networkName || 'Stokab' }}
              </p>
            </div>

            <div class="info-item">
              <label class="text-caption">&nbsp;</label>
              <p
                class="info-item-value text-subtitle-1 d-flex align-center cursor"
                @click="initData"
              >
                <v-icon
                  small
                  dense
                  color="white"
                  class="pr-1"
                >
                  mdi-pencil
                </v-icon>
                Ändra adress
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="image-area">
        </v-col>
      </v-row>
    </v-col>

    <v-col
      v-if="stokabAccesses.length > 0"
      cols="12"
      class="products-area"
    >
      <p class="text-h4 font-weight-bold mb-2 pl-2">
        Vänligen välj tjänst
      </p>
      <v-radio-group
        v-model="accessId"
        column
        hide-details
        class="mt-0 pt-0"
      >
        <v-radio
          v-if="access"
          :value="access.accessId"
          :label="`${access.networkName}${access.population ? ' - ' + access.population : ''}`"
          class="product-item"
        />
        <v-radio
          v-for="(item, index) in stokabAccesses"
          :key="index"
          :value="item.pointId"
          label="Företagsfiber"
          class="product-item"
        />
      </v-radio-group>
    </v-col>

    <v-col cols="12" class="products-area">
      <p class="text-h4 font-weight-bold mb-2 pl-2">
        Välj den hastighet som passar dig bäst
      </p>
      <v-radio-group
        v-model="productId"
        column
        hide-details
        class="mt-0 pt-0"
      >
        <div class="d-flex product-items">
          <v-radio
            v-for="(item, index) in products"
            :key="index"
            :value="item.id"
            :label="item.speed"
            class="product-item"
          />
        </div>
      </v-radio-group>
      <p
        v-if="!!selectedProduct"
        class="text-subtitle-1 grey--text"
      >
        {{ selectedProduct.serviceHeader }}
      </p>
    </v-col>

    <template v-if="productId && selectedProduct">
      <v-col cols="12" class="mt-5">
        <p class="text-h4 font-weight-bold mb-2">
          Total
        </p>

        <v-row>
          <v-col cols="12" sm="7" class="product-info">
            <div class="d-flex justify-space-between w-full">
              <div class="product-info-item">
                <span class="text-subtitle-1 grey--text">Månadskostnad</span>
                <span class="text-subtitle-1 font-weight-bold">{{ selectedProduct.price }} kr/mån</span>
              </div>

              <div class="product-info-item">
                <span class="text-subtitle-1 grey--text">Bindningstid</span>
                <span class="text-subtitle-1 font-weight-bold">{{ selectedProduct.term }} mån</span>
              </div>
            </div>

            <div class="d-flex justify-space-between w-full">
              <div class="product-info-item">
                <span class="text-subtitle-1 grey--text">Startavgift</span>
                <span class="text-subtitle-1 font-weight-bold">{{ selectedProduct.start }} kr/mån</span>
              </div>

              <div class="product-info-item">
                <span class="text-subtitle-1 grey--text">Uppsägningstid</span>
                <span class="text-subtitle-1 font-weight-bold">{{ selectedProduct.termCancel }} mån</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-4 start-date-area">
        <p class="text-h4 font-weight-bold mb-0">
          Startdatum
        </p>

        <v-row>
          <v-col cols="12" sm="7">
            <v-radio-group
              v-model="dateType"
              column
              hide-details
              class="mt-0 pt-0"
            >
              <div class="date-item">
                <v-radio
                  value="now"
                  label="Omgående"
                >
                  <template v-slot:label>
                    <div class="black--text">Omgående</div>
                  </template>
                </v-radio>
                <span class="pl-8 grey--text">
                  Fakturering på på e-post med 10 dagars förfallotid.
                </span>
              </div>

              <div class="date-item">
                <v-radio
                  value="custom"
                  label="Framtida Startdatum"
                >
                  <template v-slot:label>
                    <div class="black--text">Annat Datum</div>
                  </template>
                </v-radio>
                <v-menu
                  v-model="showExpectedDeliveryCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expectedDelivery"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      class="calendar-input mb-2"
                      hide-details=""
                    />
                  </template>
                  <v-date-picker
                    v-model="expectedDelivery"
                    :min="minDeliveryDate"
                    @input="showExpectedDeliveryCalendar = false"
                  />
                </v-menu>
                <span class="pl-8 grey--text">
                  Fakturering på på e-post med 10 dagars förfallotid.
                </span>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="actions-area">
        <v-row>
          <v-col cols="12" sm="7" class="d-flex justify-space-between">
            <v-btn
              class="btn-back"
              @click="gotoBack"
            >
              Tillbaka
            </v-btn>

            <v-btn
              class="btn-next"
              :disabled="!isAvailableNext"
              @click="gotoNext"
            >
              Gä till kassan
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { mapActions } from 'vuex'
  import moment from 'moment-timezone'

  import { showErrorDialog } from '@/utils/swalDialog'
  import util from '@/utils'

  export default {
    props: {
      address: {
        type: String,
      },

      access: {
        type: Object,
        default: () => ({}),
      },

      stokabProduct: {
        type: Object,
        default: () => ({}),
      },

      products: {
        type: Array,
        default: () => ([]),
      },

      services: {
        type: Array,
        default: () => ([]),
      },

      stokabAccesses: {
        type: Array,
        default: () => ([]),
      }
    },

    data() {
      return {
        productId: '',
        dateType: '',
        showExpectedDeliveryCalendar: false,
        expectedDelivery: null,
        accessId: '',
      }
    },

    watch: {
      accessId(value, oldValue) {
        if (!oldValue) {
          return
        }

        this.chooseAccess()
      },

      products: {
        deep: true,
        immediate: true,
        handler(value) {
          if (!value || value.length === 0) {
            this.productId = ''
            return
          }

          this.productId = value[0].id
        },
      },
    },

    computed: {
      selectedProduct() {
        if (!this.productId) {
          return null
        }

        const product = this.products.find(item => item.id === this.productId)
        return product
      },

      selectedService() {
        if (!this.selectedProduct) {
          return null
        }

        const service = this.services.find(serviceItem => serviceItem.service === this.selectedProduct.serviceName || serviceItem.service === this.selectedProduct.serviceAlias)
        return service
      },

      minDeliveryDate() {
        return moment().format('YYYY-MM-DD')
      },

      isAvailableNext() {
        if (!this.productId) {
          return false
        }

        if (this.dateType === 'now') {
          return true
        }

        if (!this.expectedDelivery) {
          return false
        }

        return true
      },

      networkName() {
        if (this.products.length === 0) {
          return ''
        }

        return this.products[0].network
      },
    },

    mounted () {
      this.accessId = this.access?.accessId
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        getAccessDetail: 'getAccessDetail',
        getStokabNetworks: 'getStokabNetworks',
      }),

      chooseAccess() {
        const selecteStokabdAccess = this.stokabAccesses.find(item => item.pointId === this.accessId)

        if (!selecteStokabdAccess) {
          this.loadAccessDetail()

          return
        }

        this.loadStokabDetail()
      },

      async loadAccessDetail() {
        this.setLoadingText('Hittar tjänster...')
        this.setLoading(true)

        try {
          const payload = {
            type: this.access.network,
            accessId: this.accessId,
            networkCategory: 'commercial',
          }

          const response = await this.getAccessDetail(payload)
          const services = response.services.filter(item => item.connection !== 'NO' || item.available !== 'NO')

          this.$emit('set-products-services', {
            services,
            products: response.products || [],
            access: this.access,
            keepStokabPoints: true,
            type: 'open-network',
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
        }

        this.setLoading(false)
      },

      async loadStokabDetail() {
        this.setLoadingText('Hittar tjänster...')
        this.setLoading(true)

        try {
          const payload = {
            pointId: this.stokabAccesses[0].pointId,
            type: this.stokabAccesses[0].type,
            networkCategory: 'commercial',
          }

          const response = await this.getStokabNetworks(payload)

          this.$emit('set-products-services', {
            products: response.productList || [],
            stokabProduct: response.product,
            keepStokabPoints: true,
            type: 'stokab',
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog('Det blev fel!', errorText)
        }

        this.setLoading(false)
      },

      gotoNext () {
        this.$emit('set-product', this.selectedProduct)
        this.$emit('set-service', this.selectedService)
        this.$emit('set-delivery-date', this.dateType === 'now' ?  moment().format('YYYY-MM-DD') : this.expectedDelivery)
        this.$emit('go-to-next')
      },

      gotoBack() {
        this.$emit('go-to-back')
      },

      initData() {
        this.$emit('init-data')
      },
    },
  }
</script>

<style lang="scss">
  .choose-network {
    .info-area {
      background: #5B46F6;
      border-radius: 15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      color: #f4f3f9;
      padding: 40px;

      .info-item {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }

      .info-item-value {
        margin-bottom: 0;
        background: rgba(255, 255, 255, 0.1);
        padding: 5px 20px;
        border-radius: 10px;

        &.cursor {
          cursor: pointer;
        }
      }
    }

    .image-area {
      border-radius: 15px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-size: cover;
      background-image: url('/static/img/search/choose_network.png');
    }

    .products-area {
      padding: 0;
      margin-top: 20px;

      .product-items {
        border: 1px solid #e1e0e9;
        border-radius: 15px;
      }
      .product-item {
        flex: 1;
        margin-bottom: 0 !important;
        padding: 20px 30px;
        border-right: 1px solid #e1e0e9;

        &:last-child {
          border-right: 0;
        }
      }
    }

    .product-info {
      background: #e4e3ef;
      border-radius: 15px;
      padding: 20px 30px;

      .product-info-item {
        flex: 0.45;
        display: flex;
        justify-content: space-between;
      }
    }

    .start-date-area {
      .date-item {
        border: 1px solid #e1e0e9;
        padding: 20px 20px;
        margin-top: 20px;
        border-radius: 15px;

        &:first-child {
          margin-top: 0;
        }
      }

      .calendar-input {
        width: 250px;
        margin-left: 30px;
      }
    }

    .actions-area {
      .btn-back {
        flex: 0.4;
      }
      .btn-next {
        flex: 0.6;
        margin-left: 20px;
      }
    }
  }
</style>
